@import '../shared/shared.styl'

.app-table-wrapper
    height: 100%
    width: 100%

    th
        white-space: nowrap

    .ant-table-cell-fix-left, .ant-table-cell-fix-right
        background: colors.gray200

    .ant-table-body
        background: colors.gray200
        overflow-y: scroll
        max-height: calc(var(--table-height) - var(--table-offset) * 1px)

    .ant-table-thead > tr > th
        background: colors.gray200
        border-color: colors.dark_background

    .ant-table-tbody > tr:not(:last-child) > td
        border-color: colors.dark_background

    .ant-collapse-item,
    .ant-collapse-content
        background: colors.gray200

    .ant-collapse-content-box
        .table-objects
            overflow: auto

        .ant-table-title,
        .ant-table-body
            background: colors.gray300
        
        .ant-table-title
            border-bottom: 1px solid colors.gray200

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td
            background: colors.gray300
            border-color: colors.gray200

        .ant-table-title
            border-radius: 0
        .ant-table-thead > tr > th:first-child
            border-top-left-radius: 0

        .ant-table-thead > tr > th:last-child
            border-top-right-radius: 0
    
    .ant-table-expanded-row 
        > td,
        &:hover > td
            background: colors.gray100

    .ant-table-tbody > tr.ant-table-row:hover > td
        background: colors.gray300

    .ant-table-cell-scrollbar
        box-shadow: 0 1px 0 1px colors.gray200

    .ant-table-tbody > tr.ant-table-row-selected > td
        background: colors.gray200

    // Checkbox styles
    .ant-checkbox .ant-checkbox-inner
        border-color: colors.gray500
        &:after
            transition: none
    .ant-checkbox-checked 
        .ant-checkbox-inner
            border-color: colors.primary
        .ant-checkbox-inner:after
            border-color: colors.gray100
    .ant-checkbox-indeterminate
        .ant-checkbox-inner
            border-color: colors.primary
            background-color: colors.primary

[data-mode='archive']
    .ant-table-cell-fix-left, .ant-table-cell-fix-right
        background: colors.gray300

    .ant-table-body
        background: colors.gray300

    .ant-table-thead > tr > th
        background: colors.gray300

    .ant-table-tbody > tr.ant-table-row:hover > td
        background: colors.gray400

    .ant-table-cell-scrollbar
        box-shadow: 0 1px 0 1px colors.gray300

    .ant-table-tbody > tr.ant-table-row-selected > td
        background: colors.gray300

    .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover, .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover
    .ant-table-thead th:hover
        background: colors.gray400